import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC5LlSd97lcdOr0LhX1wWEmamTc851QM8I",
  authDomain: "xeric-coming-soon.firebaseapp.com",
  projectId: "xeric-coming-soon",
  storageBucket: "xeric-coming-soon.appspot.com",
  messagingSenderId: "72035699641",
  appId: "1:72035699641:web:cabc7c1085c8b66aa6c468",
  measurementId: "G-BCSM6T3VSJ",
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const app = initializeApp(firebaseConfig);
getAnalytics(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
