import React from "react";
import PrettyCactus from "./cactus.png";
import { ReactComponent as LogoSVG } from "./logo-text.svg";
import { ReactComponent as DiscordSVG } from "./discord.svg";
import { ReactComponent as TwitterSVG } from "./twitter.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="menu-bar">
        <LogoSVG className="logo" />

        <div className="social-container">
          <div className="social-label">Join The Community!</div>
          <div className="social-icons">
            <a
              href="https://discord.gg/afYQUPWbZD"
              target="_blank"
              rel="noreferrer"
            >
              <DiscordSVG className="social-icon discord" />
            </a>
            <a
              href="https://twitter.com/xericxyz"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterSVG className="social-icon twitter" />
            </a>
          </div>
        </div>
      </div>

      <header className="App-header">
        <h1 className="heading">Coming Soon</h1>
      </header>
      <img className="pretty-cactus" src={PrettyCactus} alt="cactus" />
    </div>
  );
}

export default App;
